<template>
  <audio id="bgMusic" src="http://music.163.com/song/media/outer/url?id=1968993201.mp3" loop="true" preload="true"></audio>
  <audio id="overMusic" src="./audio/over.mp3" preload="true"></audio>
  <audio id="enterMusic" src="./audio/enter.mp3" preload="true"></audio>
  <audio id="leaveMusic" src="./audio/leave.mp3" preload="true"></audio>
  <div class="bg" style="height:100vh;width:100%;" v-show="check">
    <canvas id="canvas" :width="width" :height="height"></canvas>
    <div class="title"></div>
    <div class="exit" @click="alertOn()" @mouseover="overMusic()"></div>
    <div class="check">
      <div :class="{light:on1}" @mouseover="over1()" @mouseleave="leave1()" @click="enterMusic()">开始游戏</div>
      <div class="spacer"></div>
      <div :class="{light:on2}" @mouseover="over2()" @mouseleave="leave2()" @click="enterMusic()">驻足回想</div>
      <div class="spacer"></div>
    </div>
  </div>
  <alertBox :width="width"></alertBox>
  <div style="height:100vh;width:100%;" v-show="!check" @click="playbgMusic()">
    <div class="check" v-show="!check" style="font-size:30px;aspect-ratio:2;left:1%;">
        <p>点击任意位置开始游戏...</p>
    </div>
  </div>
</template>

<script>
import alertBox from './components/alertBox.vue'

export default {
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      autoPlay: true,
      alert: false,
      on1: false,
      on2: false,
      check: false,
    }
  },
  components: {
    alertBox,
  },
  mounted() {
    let that = this;
    window.onresize =() =>{
      return (()=>{
        window.clientHeight = document.body.clientHeight;
        that.height = window.clientHeight;
        window.clientWidth = document.body.clientWidth;
        that.width = window.clientWidth;
      })()
    };
    // this.draw()
  },
  methods: {
    draw() {
      /** @type {HTMLCanvasElement} */
      var ctx = document.getElementById('canvas').getContext('2d');
      ctx.translate(this.width/2, this.height/2)
      ctx.beginPath();//beginPath()开始路径
      ctx.ellipse(0,0,this.width/1.2,this.height/1.2,0,0,Math.PI*2);
      ctx.fillStyle="#058";
      ctx.strokeStyle="#000";
      ctx.fill();
      ctx.closePath();//closePath()结束路径
    },
    playbgMusic() {
      var audio = document.getElementById("bgMusic");
      audio.play();
      this.check = true;
    },
    alertOn() {
      this.$store.state.alert=true;
      this.enterMusic()
    },
    overMusic() {
      var audio = document.getElementById("overMusic");
      audio.play();
    },
    enterMusic() {
      var audio = document.getElementById("enterMusic");
      audio.play();
    },
    leaveMusic() {
      var audio = document.getElementById("leaveMusic");
      audio.play();
    },
    over1() {
      this.on1 = true
      this.overMusic()
    },
    over2() {
      this.on2 = true
      this.overMusic()
    },
    leave1() {
      this.on1 = false
    },
    leave2() {
      this.on2 = false
    },
  }
}
</script>

<style>
@font-face {
  font-family: FZZJ;
  src: url(./FZZJ-GJPLSJW.ttf);
}
.bg {
  background: url(https://cloud.fullcomb.top/private/source/gallery/img/mask.png) center center no-repeat, url(https://cloud.fullcomb.top/private/source/gallery/img/zhejingzhidie.png) center center no-repeat;
  background-size: cover;
}
.title {
  position: absolute;
  left: 10%;
  top: 6%;
  height: 30%;
  aspect-ratio: 0.57;
  background-image: url(https://cloud.fullcomb.top/private/source/gallery/img/title.png);
  background-size: cover;
}
.exit {
  position: absolute;
  right: 3%;
  bottom: 6%;
  height: 10%;
  aspect-ratio: 1;
  background-image: url(https://cloud.fullcomb.top/private/source/gallery/img/exit.png);
  background-size: cover;
  cursor: pointer;
}
.check {
  user-select: none; 
  position: absolute;
  left: 10%;
  bottom: 5%;
  height: 200px;
  aspect-ratio: 0.9;
  font-family: FZZJ, '隶书', '华文隶书', '楷体';
  font-size: 40px;
  color: white;
  text-align: center;
}
.spacer {
  width:100%;
  height: 1px;
  margin-bottom: 20px;
  background: linear-gradient(to right, rgba(255,255,255,0.1),rgba(255,255,255,0.7),rgba(255,255,255,0.1));
}
.light {
  cursor: pointer;
  text-shadow: 0 0 30px white;
}
</style>
