<template>
    <div class="box" v-show="$store.state.alert">
        <div class="imgBox"></div>
        <div style="height: 100%;">
            <div class='yes' @click="pressYes()" @mouseover="enterYes()" @mouseout="switchYes()"></div>
            <div class='no' @click="pressNo()" @mouseover="enterNo()" @mouseout="switchNo()"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            yes_url: 'url(https://cloud.fullcomb.top/private/source/gallery/img/yes.png)',
            no_url: 'url(https://cloud.fullcomb.top/private/source/gallery/img/no.png)',
        }
    },
    props: [
        'width',
    ],
    computed: {
        imgWidth() {
            return `${Math.min(this.width/3+300, window.innerWidth)}px`;
        },
        textV() {
            return `${((this.width/3+250)/2.25)/3-10}px`
        },
        pos() {
            return `${this.width/2-300}px`
        }
    },
    methods: {
        switchYes() {
            if(this.yes_url == 'url(https://cloud.fullcomb.top/private/source/gallery/img/yes.png)') {
                this.yes_url = 'url(https://cloud.fullcomb.top/private/source/gallery/img/yes_2.png)'
            } else {
                this.yes_url = 'url(https://cloud.fullcomb.top/private/source/gallery/img/yes.png)'
            }
            
        },
        switchNo() {
            if(this.no_url == 'url(https://cloud.fullcomb.top/private/source/gallery/img/no.png)') {
                this.no_url = 'url(https://cloud.fullcomb.top/private/source/gallery/img/no_2.png)'
            } else {
                this.no_url = 'url(https://cloud.fullcomb.top/private/source/gallery/img/no.png)'
            }
        },
        enterYes() {
            this.switchYes()
            var audio = document.getElementById("overMusic");
            audio.play();
        },
        enterNo() {
            this.switchNo()
            var audio = document.getElementById("overMusic");
            audio.play();
        },
        pressYes() {
            var audio = document.getElementById("leaveMusic");
            this.switchYes()
            audio.play();
            setTimeout(()=>{history.go(-1)},500)
            this.switchYes()
        },
        pressNo() {
            var audio = document.getElementById("leaveMusic");
            audio.play();
            this.switchNo()
            this.$store.state.alert = false;
            this.switchNo()
        }
    }
}
</script>

<style scoped>
.box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 150px;
    background-color: rgba(0, 0, 0, 0.5);
}
.imgBox {
    background: url(https://cloud.fullcomb.top/private/source/gallery/img/alert.png) center center no-repeat;
    background-size: 100% 100%;
    width: v-bind(imgWidth);
    display: inline-block;
    aspect-ratio: 3.5;
}
.yes, .no {
    width:180px;
    aspect-ratio: 4;
    display: inline-block;
    background-size: 180px;
    cursor: pointer;
}
.yes {
    background-image: v-bind(yes_url);

}
.no {
    background-image: v-bind(no_url);
}
</style>